<template>
  <v-main>
    <error-snackbar
      v-if="showErrorSnackbar"
      :snackbarText="snackbarText"
      @hidde="showErrorSnackbar = false"
    />
    <v-container style="padding: 0px">
      <v-form @submit.prevent="sendEmail">
        <v-row
          no-gutters
          align="start"
          justify="center"
          style=""
          :style="
            $vuetify.breakpoint.smAndDown
              ? 'padding: 100px 20px'
              : 'padding: 50px 0px'
          "
        >
          <v-col
            v-if="successSendEmail"
            cols="12"
            xl="4"
            lg="6"
            md="6"
            sm="12"
            :style="
              $vuetify.breakpoint.smAndDown
                ? ''
                : 'padding-right: 104px; padding-top: 12px'
            "
          >
            <img
              v-if="!$vuetify.breakpoint.smAndDown"
              src="@/assets/img/PTransLogoBlue.png"
              width="183px"
              alt="Logo"
            />
            <h3
              class="loginTitle"
              :style="$vuetify.breakpoint.smAndDown ? '' : 'margin-top: 72px'"
            >
              Відновлення пароля
            </h3>
            <h4
              style="
                font-weight: 500;
                font-size: 20px;
                letter-spacing: 0.1em;
                color: #243949;
              "
            >
              Запит успішно прийнятий
            </h4>
            <p class="loginText">
              На пошту
              <strong
                >{{ user.email.substr(0, 3) }}***{{
                  user.email.substr(
                    user.email.length - 14,
                    user.email.length - 1
                  )
                }}</strong
              >, вказану під час реєстрації, зараз прийде лист. У ньому буде
              посилання, по якому слід перейти, щоб Ви змогли створити новий
              пароль. <strong>Дуже важливо</strong> не забути перевірити папку
              "СПАМ" у своїй поштовій скриньці, якщо лист довго не надходить!
            </p>
            <submit-button @click="$router.push('/')">На головну</submit-button>
          </v-col>
          <v-col
            v-else
            cols="12"
            xl="4"
            lg="6"
            md="6"
            sm="12"
            :style="
              $vuetify.breakpoint.smAndDown
                ? ''
                : 'padding-right: 104px; padding-top: 12px'
            "
          >
            <img
              v-if="!$vuetify.breakpoint.smAndDown"
              src="@/assets/img/PTransLogoBlue.png"
              width="183px"
              alt="Logo"
            />
            <h3
              class="loginTitle"
              :style="$vuetify.breakpoint.smAndDown ? '' : 'margin-top: 72px'"
            >
              Відновлення пароля
            </h3>
            <p class="loginText" style="margin: 8px 0px 0px 0px">
              Не хвилюйтеся, це трапляється з усіма нами. Введіть свою
              електронну пошту нижче, щоб відновити пароль
            </p>
            <v-text-field
              style="margin-top: 40px; border-radius: 10px"
              dense
              outlined
              label="Email"
              :placeholder="'type_here' | localize"
              hide-details
              color="#E2E2E2"
              background-color="#FFF"
              height="56px"
              v-model="user.email"
              :error-messages="emailError"
            >
              <template v-slot:append>
                <v-icon
                  color="error"
                  style="margin-top: 8px"
                  v-if="emailError.length > 0"
                  >mdi-alert-circle</v-icon
                >
              </template>
            </v-text-field>
            <v-btn
              class="detailBtn"
              width="100%"
              style="margin-top: 30px"
              type="submit"
            >
              Підтвердити
            </v-btn>
          </v-col>
          <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="6" class="py-0">
            <swiper ref="mySwiper" :options="swiperOptions">
              <swiper-slide
                v-for="i in 3"
                :key="i"
                :cssMode="true"
                :navigation="true"
                :pagination="true"
                :mousewheel="true"
                :keyboard="true"
                :modules="swiperOptions.modules"
              >
                <img
                  src="@/assets/img/routeImg.png"
                  width="100%"
                  height="100%"
                  class="loginImg"
                  style="user-select: none"
                />
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-main>
</template>

<script>
import swiperOptionMixin from "../../../../mixins/swiperOptionMixin";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import authService from "../../../../requests/Auth/authService.js";
import ErrorSnackbar from "../../../UI/Snackbars/errorSnackbar.vue";
import SubmitButton from "../../../UI/Buttons/submitButton.vue";
export default {
  components: { ErrorSnackbar, SubmitButton },
  mixins: [swiperOptionMixin, validationMixin],
  data: () => ({
    user: {
      email: "",
    },
    swiperOptions: {
      loop: true,
      initialSlide: 0,
      autoplay: {
        delay: 1500,
        disableOnInteraction: true,
      },
      speed: 800,
    },
    snackbarText: "",
    isShowPassword: false,
    showErrorSnackbar: false,
    successSendEmail: false,
  }),
  validations: {
    user: {
      email: {
        email,
        required,
      },
    },
  },
  methods: {
    ...mapActions(["updateInfoLogged"]),
    async sendEmail() {
      this.$v.$touch();
      this.showErrorSnackbar = false;
      if (!this.$v.$invalid) {
        let email = new FormData();
        email.append("email", this.user.email);
        await authService
          .resetPassword(email)
          .then(() => {
            localStorage.setItem("userEmail", this.user.email);
            this.successSendEmail = true;
          })
          .catch(() => {
            this.snackbarText = "Користувача з таким email не існує";
            this.showErrorSnackbar = true;
            setTimeout(() => {
              this.showErrorSnackbar = false;
            }, 3000);
          });
      }
    },
  },
  computed: {
    emailError() {
      const errors = [];
      let field = this.$v.user.email;
      if (!field.$dirty) {
        return errors;
      } else if (!field.required) {
        errors.push("");
      }
      if (!field.email) {
        errors.push("");
      }
      return errors;
    },
  },
};
</script>

<style scoped>
.loginImg {
  border-radius: 30px;
}
@media only screen and (max-width: 1023px) {
  .loginTitle {
    color: #1b1b1b;
    font-family: "MacPaw Fixel";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
@media only screen and (min-width: 1024px) {
  .loginTitle {
    color: #1b1b1b;
    font-family: "MacPaw Fixel";
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
.loginText {
  color: #4b5262;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.detailBtn {
  border-radius: 10px;
  background: #144fa9 !important;
  color: #fafafa !important;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 48px !important;
  padding: 12px 16px;
  text-transform: none;
}
</style>
<style>
.swiper-pagination-bullet-active {
  background: #fed500 !important;
  width: 32px;
  height: 10px;
  border-radius: 5px;
  transition: width 0.5s, border-radius 0.3s;
}
.swiper-pagination-bullet {
  background-color: #fafafa;
  opacity: 1;
}
.v-text-field--outlined.v-input--dense .v-label {
  top: 18px;
}
.v-text-field--outlined.v-input--dense .v-label--active {
  top: 10px !important;
}
</style>